import { React, useState } from "react";

const ApiCall = () => {
  const [apiOutput, setApiOutput] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error

  const postCall = (data, url, aliasName) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage(aliasName + ":: event was successfully!");
        setError(null);
      })
      .catch((error) => {
        setError(
          aliasName + "Failed to execute the request. Please try again."
        );
        setMessage(null);
      });
  };

  const getCall = async (data, url, aliasName) => {
    console.log("Getting the data for " + aliasName);
    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setApiOutput(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError(aliasName + ":: Error fetching data : " + error.message);
        }
      });
    return apiOutput;
  };
  return {
    setPostData: postCall,
    getData: getCall,
    message: message,
  };
};

export default ApiCall;
