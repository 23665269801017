import React, { useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./Login.css";
import { iepaProd } from "./constants/global";
import { useNavigate } from "react-router-dom"; // As

import Prototypes from "prop-types";

const Login = ({ setToken }) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error

  const navigate = useNavigate(); // For page navigation

  const handleSuccessfulLogin = (isAuthenticated) => {
    // console.log("isAuthenticated::" + isAuthenticated);
    if (isAuthenticated) {
      navigate("/Dashboard");
    }
  };

  useEffect(() => {
    handleSuccessfulLogin(localStorage.getItem("token") != null);
    // Hide the rest of the app when the login page is displayed
    const appContent = document.getElementById("app"); // Assumes your app content is wrapped in a div with id 'app'
    if (appContent) appContent.style.display = "none";

    return () => {
      // Restore the app content when leaving the login page
      if (appContent) appContent.style.display = "";
    };
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    const data = {
      username: username,
      passwordHash: password,
    };

    await fetch(`${iepaProd.iepaUserSrvAPI}/user/login`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Received the User Login information successfully!");
        setError(null);
        localStorage.clear();
        setToken(data.token);

        localStorage.setItem("username", data.username);

        //resetForm();
        handleSuccessfulLogin(true);
      })
      .catch((error) => {
        setError("Failed to authenticate the User. Please try again.");
        setMessage(null);
      });
  };
  // if (token) {
  //   return (<redirec)
  // }
  return (
    <div className="login-page">
      <div className="login-image">
        <img
          src="https://cdn.pixabay.com/photo/2021/10/11/17/54/technology-6701504_1280.jpg"
          alt="Login"
        />
      </div>
      <div className="login-form">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="text"
              placeholder="Enter your username"
              required
              onChange={(ev) => setUsername(ev.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input
              type="password"
              placeholder="Enter your password"
              required
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
};

Login.prototype = {
  setToken: Prototypes.func.isRequired,
};
export default Login;
