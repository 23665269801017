import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useTheme,
  Table,
  TableBody,
  // TableCell,
  // TableHead,
  // TableRow,
  // Modal,
  // Checkbox,
  // TableContainer,
  InputAdornment,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { iepaProd } from "../../constants/global";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ApiCall from "../../components/ApiCall";

const Taxation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [assignedLocations, setAssignedLocations] = useState([]);
  const navigate = useNavigate();
  const [taxation, setTaxation] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [taxData, setTaxData] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState({});
  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState(null); // Error handling

  const { data, getData } = ApiCall();
  const fetchTaxRealm = async () => {
    await fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/tax/rates/org/1`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch users");
        }
        return response.json();
      })
      .then((data) => {
        setTaxData(data);
        setTaxation(data);
        console.log(data);
      })
      .catch((error) => setError("Error fetching user data"));
  };

  const fetchTaxData = () => {
    setTaxData(
      getData(
        null,
        `${iepaProd.iepaInventorySrvAPI}/inventory/tax/realm/org/1`,
        "Taxation"
      )
    );

    console.log("Data");
    console.log(taxData);
  };

  useEffect(() => {
    fetchTaxRealm();
    // console.log(taxData);
    // fetchTaxData();
    // fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/active/org/1`)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     if (Array.isArray(data)) {
    //       setTaxation(data); // Set the response as the routes array
    //     } else {
    //       console.error("Expected an array of routes, but got:", data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching data:", error);
    //   });
  }, []);

  // Filtered rows based on search query
  const filteredRows = taxData
    ? taxData.filter((taxation) => {
        const searchTerm = filterText ? filterText.toLowerCase() : "";
        return (
          taxation?.id?.toString().toLowerCase().includes(searchTerm) ||
          taxation?.name?.toString().toLowerCase().includes(searchTerm) ||
          taxation?.taxRegime?.taxRealm?.name
            ?.toString()
            .toLowerCase()
            .includes(searchTerm) ||
          taxation?.taxRegime?.name
            ?.toString()
            .toLowerCase()
            .includes(searchTerm) ||
          taxation?.rate?.toString().includes(searchTerm) ||
          taxation?.status?.toString().toLowerCase().includes(searchTerm) ||
          taxation?.activeStartDate?.toString().includes(searchTerm) ||
          taxation?.activeEndDate?.toString().includes(searchTerm)
        );
      })
    : [];

  const columns = [
    { field: "id", headerName: "Seq. No.", width: 80 },
    {
      field: "taxCtryname",
      headerName: "Tax CategoryName",
      valueGetter: (params, row) => row?.taxRegime?.taxRealm?.name,
      flex: 2,
    },
    {
      field: "taxName",
      headerName: "Tax Name",
      valueGetter: (params, row) => row?.taxRegime?.name,
      flex: 2,
    },
    { field: "name", headerName: "Tax Rate Name", flex: 2 },
    { field: "rate", headerName: "Rate %", flex: 2 },
    { field: "status", headerName: "Active/Not Active", flex: 2 },
    { field: "activeStartDate", headerName: "Start Date", flex: 3 },
    { field: "activeEndDate", headerName: "End Date", flex: 3 },
  ];

  return (
    <Box m="20px">
      <Header title="Manage Taxation" subtitle="Taxation Details" />
      <Box display="flex" mb="20px">
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate("../CreateTaxation")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Add Taxation
        </Button>

        <Button
          color="primary"
          variant="contained"
          disabled={selectedRows.length !== 1}
          // onClick={() => navigate("../ViewTaxation")}
          onClick={() => {
            navigate("../ViewTaxation", {
              // state: { selectedRows: selectedRows, taxData: taxData }, // Passing selectedRows state to ViewTax component
              state: { selectedRows: selectedRows }, // Passing selectedRows state to ViewTax component
            });
          }}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          View Taxation
        </Button>

        <TextField
          label="Search"
          variant="outlined"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          sx={{ width: "30%", margin: "10px" }}
        />
      </Box>
      <Box
        // m="-25px 0 0 0"
        height="78vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid grey",
            whiteSpace: "normal", // Allows text wrapping
            wordWrap: "break-word", // Ensures text breaks properly
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            fontSize: "14px",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[900],
            height: "5vh",
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={filteredRows}
          columns={columns}
          checkboxSelection
          onRowSelectionModelChange={(newSelection) =>
            setSelectedRows(newSelection)
          }
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Taxation;
