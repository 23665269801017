import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  MenuItem,
  IconButton,
} from "@mui/material";

import { useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import { iepaProd } from "../../constants/global";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const ViewTaxation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeTab, setActiveTab] = useState(0); // To manage active tab
  const navigate = useNavigate(); // For page navigation

  const [loading, setLoading] = useState(false);
  // const [loadingLocations, setLoadingLocations] = useState(false);
  // const defaultCities = [{ city: "Select City", state: "Select State" }];
  // const [cities, setCities] = useState(defaultCities);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null); //Error
  const [locationOptions, setLocationOptions] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  // const [contactId, setContactId] = useState(null);
  // const [addressId, setAddressId] = useState(null);
  const [taxRecord, setTaxRecord] = useState(null);
  const location = useLocation();

  const { selectedRows } = location.state || {};

  console.log("selectedRows", selectedRows);

  // When selectedRows change, update the taxRecord
  useEffect(() => {
    if (selectedRows) {
      // Assuming selectedRows contains an array with one object that has an 'id' field
      setSelectedId(selectedRows[0]);
      fetchTaxRecord(selectedRows[0]);
      // const tax = taxData.find((p) => p.id === selectedId);
      // setTaxRecord(tax);
    } else {
      setTaxRecord(null); // Reset if no product is selected
    }
  }, [selectedRows]); // Re-run whenever selectedRows or products change

  // console.log("taxRecord", taxRecord);
  // Handle dynamic initial values based on taxRecord

  const fetchTaxRecord = async (rateId) => {
    console.log("Fetching Tax Record for ID:" + rateId);
    await fetch(
      `${iepaProd.iepaInventorySrvAPI}/inventory/tax/rates/${rateId}/org/1`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the data to check structure
        setTaxRecord(data);
      })
      .catch((error) => {
        if (error.message.substring("JSON input") <= 0) {
          setError("Error fetching City State information: " + error.message);
        }
      });
  };

  const initialValues = {
    taxCtgrId: taxRecord?.taxRegime?.taxRealm?.id,
    taxCtgrName: taxRecord?.taxRegime?.taxRealm?.name,
    taxCtgrStatus: taxRecord?.taxRegime?.taxRealm?.status,
    taxCtgrStartDate: taxRecord?.taxRegime?.taxRealm?.activeStartDate,
    taxCtgrEndDate: taxRecord?.taxRegime?.taxRealm?.activeEndDate,
    taxNameId: taxRecord?.taxRegime?.id,
    taxNameName: taxRecord?.taxRegime?.name,
    taxNameStatus: taxRecord?.taxRegime?.status,
    taxNameStartDate: taxRecord?.taxRegime?.activeStartDate,
    taxNameEndDate: taxRecord?.taxRegime?.activeEndDate,
    taxRateId: taxRecord?.id,
    taxRateName: taxRecord?.name,
    taxRateValue: taxRecord?.rate,
    taxRateStatus: taxRecord?.status,
    taxRateStartDate: taxRecord?.activeStartDate,
    taxRateEndDate: taxRecord?.activeEndDate,
  };
  const handleClose = () => {
    navigate("/Taxation"); // Redirect to /CustomerList
  };

  const handleTaxCatgFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Tax Category Form");
    const data = {
      id: values.taxCtgrId,
      name: values.taxCtgrName,
      status: values.taxCtgrStatus,
      activeStartDate: values.taxCtgrStartDate,
      activeEndDate: values.taxCtgrEndDate,
      orgId: "1",
      updatedBy: 1,
    };

    console.log(data);

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/tax/realm/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Tax Category updated successfully!");
        setError(null);
        // resetForm();
        console.log("Tax Category Id:" + data.id);

        // setAddressId(data.id);
      })
      .catch((error) => {
        setError("Failed to update Tax Category. Please try again.");
        setMessage(null);
      });
  };

  const handleTaxNameFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Tax Name Form");

    const data = {
      id: values.taxNameId,
      name: values.taxNameName,
      status: values.taxNameStatus,
      activeStartDate: values.taxNameStartDate,
      activeEndDate: values.taxNameEndDate,
      orgId: "1",
      updatedBy: 1,
    };

    console.log(data);

    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/tax/regime/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Tax Name updated successfully!");
        setError(null);
        // resetForm();
        console.log("Tax Name Id:" + data.id);

        // setContactId(data.id);
      })
      .catch((error) => {
        setError("Failed to update Tax Name. Please try again.");
        setMessage(null);
      });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleTaxRateFormSubmit = (values, { resetForm }) => {
    console.log("Submitting the Tax Rate Form");

    const data = {
      id: values.taxRateId,
      name: values.taxRateName,
      status: values.taxRateStatus,
      activeStartDate: values.taxRateStartDate,
      activeEndDate: values.taxRateEndDate,
      rate: values.taxRateValue,
      orgId: "1",
      updatedBy: 1,
    };

    console.log(data);
    fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/tax/rates/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Tax Rate updated successfully!");
        setError(null);
        // resetForm();
        // console.log("Tax Rate Id:" + data.id);
      })
      .catch((error) => {
        setError("Failed to update Tax Rate. Please try again.");
        setMessage(null);
      });
  };

  useEffect(() => {
    setLoading(true);
  }, []);
  console.log("initialValues", initialValues);

  // const handleTaxSubmit = (values, { resetForm }) => {
  //   const data = {
  //     name: values.routeName,
  //     shortName: values.routeShortName,
  //     description: "route description",
  //     code: values.routeCode,
  //     type: values.temporary,
  //     activeStartDate: values.startDate,
  //     activeEndDate: values.endDate,
  //     orgId: "1",
  //     createdBy: 1,
  //   };

  //   fetch(`${iepaProd.iepaInventorySrvAPI}/inventory/routes/save/org/1`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(data),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setMessage("Route added successfully!");
  //       setError(null);
  //       resetForm();
  //     })
  //     .catch((error) => {
  //       setError("Failed to add Route. Please try again.");
  //       setMessage(null);
  //     });
  // };

  const tabContent = [
    {
      label: "Tax Category",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewTaxCategorySchema}
          onSubmit={handleTaxCatgFormSubmit}
          enableReinitialize="true"
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  id="taxCtgrId"
                  label="Seq No."
                  inputProps={{ readOnly: true }}
                  value={values.taxCtgrId || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxCtgrId && Boolean(errors.taxCtgrId)}
                  helperText={touched.taxCtgrId && errors.taxCtgrId}
                />
                <TextField
                  fullWidth
                  required
                  id="taxCtgrName"
                  label="Tax Category Name"
                  value={values.taxCtgrName || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxCtgrName && Boolean(errors.taxCtgrName)}
                  helperText={touched.taxCtgrName && errors.taxCtgrName}
                />
                <TextField
                  fullWidth
                  // required
                  select
                  id="taxCtgrStatus"
                  name="taxCtgrStatus"
                  label="Active Status"
                  value={values.taxCtgrStatus || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxCtgrStatus && Boolean(errors.taxCtgrStatus)}
                  helperText={touched.taxCtgrStatus && errors.taxCtgrStatus}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxCtgrStartDate
                    id="taxCtgrStartDate"
                    name="taxCtgrStartDate"
                    // defaultValue={
                    //   values.taxCtgrStartDate
                    //     ? values.taxCtgrStartDate
                    //     : new Date()
                    // }
                    value={
                      values.taxCtgrStartDate
                        ? new Date(values.taxCtgrStartDate)
                        : null
                    }
                    // selected={
                    //   values.taxCtgrStartDate
                    //     ? values.taxCtgrStartDate
                    //     : new Date()
                    // }
                    // onChange={handleChange}
                    label="Start Date"
                    onChange={(val) => {
                      setFieldValue("taxCtgrStartDate", val);
                    }}
                    error={
                      touched.taxCtgrStartDate &&
                      Boolean(errors.taxCtgrStartDate)
                    }
                    helperText={
                      touched.taxCtgrStartDate && errors.taxCtgrStartDate
                    }
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxCtgrEndDate
                    value={
                      values.taxCtgrEndDate
                        ? new Date(values.taxCtgrEndDate)
                        : null
                    }
                    label="End Date"
                    onChange={(val) => {
                      setFieldValue("taxCtgrEndDate", val);
                    }}
                    error={
                      touched.taxCtgrEndDate && Boolean(errors.taxCtgrEndDate)
                    }
                    helperText={touched.taxCtgrEndDate && errors.taxCtgrEndDate}
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>

                <Box display="flex" gap={2}>
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                  >
                    Save
                  </Button>

                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: colors.blueAccent[700],
                      color: colors.primary[400],
                      fontSize: "14px",
                      fontWeight: "bold",
                      margin: "10px",
                    }}
                    variant="contained"
                    onClick={() => {
                      resetForm(); // Reset form when clicked
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Tax Name",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewTaxNameSchema}
          onSubmit={handleTaxNameFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  inputProps={{ readOnly: true }}
                  id="taxNameId"
                  label="Seq. No."
                  value={values.taxNameId || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxNameId && Boolean(errors.taxNameId)}
                  helperText={touched.taxNameId && errors.taxNameId}
                />
                <TextField
                  fullWidth
                  required
                  id="taxNameName"
                  label="Tax Name"
                  value={values.taxNameName || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxNameName && Boolean(errors.taxNameName)}
                  helperText={touched.taxNameName && errors.taxNameName}
                />
                <TextField
                  fullWidth
                  // required
                  select
                  id="taxNameStatus"
                  name="taxNameStatus"
                  label="Active/Not Active"
                  value={values.taxNameStatus || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxNameStatus && Boolean(errors.taxNameStatus)}
                  helperText={touched.taxNameStatus && errors.taxNameStatus}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxNameStartDate
                    value={
                      values.taxNameStartDate
                        ? new Date(values.taxNameStartDate)
                        : null
                    }
                    label="Start Date"
                    onChange={(val) => {
                      setFieldValue("taxNameStartDate", val);
                    }}
                    error={
                      touched.taxNameStartDate &&
                      Boolean(errors.taxNameStartDate)
                    }
                    helperText={
                      touched.taxNameStartDate && errors.taxNameStartDate
                    }
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxNameEndDate
                    value={
                      values.taxNameEndDate
                        ? new Date(values.taxNameEndDate)
                        : null
                    }
                    label="End Date"
                    onChange={(val) => {
                      setFieldValue("taxNameEndDate", val);
                    }}
                    error={
                      touched.taxNameEndDate && Boolean(errors.taxNameEndDate)
                    }
                    helperText={touched.taxNameEndDate && errors.taxNameEndDate}
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                </Button>

                <Button
                  type="reset"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
    {
      label: "Tax Rate",
      content: (
        <Formik
          initialValues={initialValues}
          validationSchema={overviewTaxRateSchema}
          onSubmit={handleTaxRateFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" gap="20px" mt="20px">
                <TextField
                  fullWidth
                  required
                  inputProps={{ readOnly: true }}
                  id="taxRateId"
                  label="Seq. No."
                  value={values.taxRateId || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxRateId && Boolean(errors.taxRateId)}
                  helperText={touched.taxRateId && errors.taxRateId}
                />
                <TextField
                  fullWidth
                  required
                  id="taxRateName"
                  label="Tax Rate Name"
                  value={values.taxRateName || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxRateName && Boolean(errors.taxRateName)}
                  helperText={touched.taxRateName && errors.taxRateName}
                />
                <TextField
                  fullWidth
                  required
                  id="taxRateValue"
                  label="Rate %"
                  value={values.taxRateValue || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxRateValue && Boolean(errors.taxRateValue)}
                  helperText={touched.taxRateValue && errors.taxRateValue}
                />
                <TextField
                  fullWidth
                  required
                  select
                  id="taxRateStatus"
                  name="taxRateStatus"
                  label="Active/Not Active"
                  value={values.taxRateStatus || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.taxRateStatus && Boolean(errors.taxRateStatus)}
                  helperText={touched.taxRateStatus && errors.taxRateStatus}
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                </TextField>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxRateStartDate
                    value={
                      values.taxRateStartDate
                        ? new Date(values.taxRateStartDate)
                        : null
                    }
                    label="End Date"
                    selected={values.taxRateStartDate}
                    onChange={(val) => {
                      setFieldValue("taxRateStartDate", val);
                    }}
                    error={
                      touched.taxRateStartDate &&
                      Boolean(errors.taxRateStartDate)
                    }
                    helperText={
                      touched.taxRateStartDate && errors.taxRateStartDate
                    }
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    taxRateEndDate
                    value={
                      values.taxRateEndDate
                        ? new Date(values.taxRateEndDate)
                        : null
                    }
                    label="End Date"
                    selected={values.taxRateEndDate}
                    onChange={(val) => {
                      setFieldValue("taxRateEndDate", val);
                    }}
                    error={
                      touched.taxRateEndDate && Boolean(errors.taxRateEndDate)
                    }
                    helperText={touched.taxRateEndDate && errors.taxRateEndDate}
                    slots={{
                      textField: (textFieldProps) => (
                        <TextField {...textFieldProps} fullWidth />
                      ),
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Box display="flex" gap={2} sx={{ mt: 3 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                >
                  Save
                </Button>

                <Button
                  type="reset"
                  sx={{
                    backgroundColor: colors.blueAccent[700],
                    color: colors.primary[400],
                    fontSize: "14px",
                    fontWeight: "bold",
                    margin: "10px",
                  }}
                  variant="contained"
                  onClick={() => {
                    resetForm(); // Reset form when clicked
                  }}
                >
                  Reset
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="View Taxation" subtitle="View/Edit Tax Details" />
      <Box display="flex" justifyContent="flex" mb="20px">
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        >
          <Typography
            sx={{ fontWeight: "bold", fontSize: "16px", marginRight: "5px" }}
          >
            Close
          </Typography>{" "}
          <CloseIcon />
        </IconButton>
        <Button
          color="secondary"
          variant="contained"
          // onClick={() => navigate("../ProductList")}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary[400],
            fontSize: "14px",
            fontWeight: "bold",
            margin: "10px",
          }}
        >
          Edit
        </Button>
      </Box>
      <Card>
        <CardContent>
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabContent.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                sx={{
                  color: activeTab === index ? "black" : colors.blueAccent[700], // Active tab color
                  fontWeight: activeTab === index ? "bold" : "normal", // Optional: Bold for active tab
                }}
              />
            ))}
          </Tabs>

          <Box mt="20px">{tabContent[activeTab].content}</Box>
        </CardContent>
      </Card>
    </Box>
  );
};

const overviewTaxCategorySchema = yup.object().shape({
  taxCtgrName: yup.string().required("Tax Category is required"),
});

const overviewTaxNameSchema = yup.object().shape({
  taxNameName: yup.string().required("Tax Name is required"),
});

const overviewTaxRateSchema = yup.object().shape({
  taxRateName: yup.string().required("Tax Rate Name is required"),
  taxRateValue: yup.string().required("Tax Rate % is required"),
});

export default ViewTaxation;
