export const iepaProd = {
  iepaInventorySrvAPI: "https://apiservices.iepa.in:8192",
  iepaOrderMgmtAPI: "https://apiservices.iepa.in:8193",
  iepaUserSrvAPI: "https://apiservices.iepa.in:8191",
};
export const iepaProd2 = {
  iepaInventorySrvAPI: "https://localhost:8480",
  iepaOrderMgmtAPI: "https://localhost:8380",
  iepaUserSrvAPI: "https://localhost:8280",
};
